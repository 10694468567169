import { PropType } from 'vue';
import { SwiperOptions } from 'swiper';
import { Props } from '../../types/core';
import { SliderItem, SliderOptions } from '../../types/slider';

// Model -------------------------------------------------------------------------------------------

export interface SliderModel {
	uid: string;
	options?: SliderOptions;
	swiperOptions?: SwiperOptions;
	items?: SliderItem[];
	isCompact?: boolean;
}

// Props -------------------------------------------------------------------------------------------

export const SliderProps: Props<SliderModel> = {
	uid: {
		type: String as PropType<SliderModel['uid']>,
		required: true,
	},
	options: {
		type: Object as PropType<SliderModel['options']>,
	},
	swiperOptions: {
		type: Object as PropType<SliderModel['swiperOptions']>,
	},
	items: {
		type: Array as PropType<SliderModel['items']>,
		default: () => [],
	},
	isCompact: {
		type: Boolean as PropType<SliderModel['isCompact']>,
		default: false,
	},
};
