var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
		'tw-p-2.5',
		'tw-cursor-pointer',
		'tw-select-none',
		'tw-pointer-events-auto',
		{ 'tw-bg-white tw-shadow-md': !_vm.isCompact } ],style:({
		'min-width': _vm.isVertical ? '56px' : '50px',
		'min-height': _vm.isVertical ? '56px' : '50px',
	}),on:{"click":function($event){return _vm.$emit('click', $event)}}},[_c('Ico',{attrs:{"name":_vm.icon,"size":_vm.isCompact ? '24px' : '22px'}}),_c('span',{staticClass:"tw-sr-only"},[_vm._v(_vm._s(_vm.text))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }