import { render, staticRenderFns } from "./ImageTile.vue?vue&type=template&id=09723c12&"
import script from "./ImageTile.ts?vue&type=script&lang=ts&"
export * from "./ImageTile.ts?vue&type=script&lang=ts&"
import style0 from "./ImageTile.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../shop/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Media: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/Media/Media.vue').default,Ico: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/Ico/Ico.vue').default})
