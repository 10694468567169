import { PropType } from 'vue';
import { Props } from '../../types/core';
import { Media, MediaLoading } from '../../types/media';
import { TrackingEvents } from '../../types/tracking';

// Model -------------------------------------------------------------------------------------------

export interface ImageTileModel {
	uid: string;
	image: Media;
	imageLoading?: MediaLoading;
	title?: string;
	url?: string;
	icon?: string;
	isSquare?: boolean;
	tracking?: TrackingEvents;
}

// Props -------------------------------------------------------------------------------------------

export const ImageTileProps: Props<ImageTileModel> = {
	uid: {
		type: String as PropType<ImageTileModel['uid']>,
		required: true,
	},
	image: {
		type: Object as PropType<ImageTileModel['image']>,
		required: true,
	},
	imageLoading: {
		type: String as PropType<ImageTileModel['imageLoading']>,
		default: MediaLoading.LAZY,
	},
	title: {
		type: String as PropType<ImageTileModel['title']>,
	},
	url: {
		type: String as PropType<ImageTileModel['url']>,
	},
	icon: {
		type: String as PropType<ImageTileModel['icon']>,
		default: 'arrow-up-right',
	},
	isSquare: {
		type: Boolean as PropType<ImageTileModel['isSquare']>,
		default: false,
	},
	tracking: {
		type: Object as PropType<ImageTileModel['tracking']>,
	},
};
