import Vue from 'vue';
import type { SwiperOptions } from 'swiper';
import { AutoplayOptions } from 'swiper/types/components/autoplay';
import { directive } from 'vue-awesome-swiper';
import { debounce } from '../../assets/js/utilities/debounce';
import ImageTile from '../ImageTile/ImageTile.vue';
import { Breakpoints } from '../../types/breakpoint';
import { ClsOptimizationMixin } from '../mixins/cls-optimization-mixin';
import { SliderProps } from './Slider.props';
import SliderItem from './partials/Slider__Item.vue';

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'Slider',
	components: { ImageTile, SliderItem },
	directives: { swiper: directive },
	mixins: [ClsOptimizationMixin],
	props: SliderProps,
	data() {
		return {
			defaultOptions: {
				autoplay: {
					delay: 300,
					stopOnLastSlide: true,
				},
				freeMode: false,
				preloadImages: false,
				slidesPerView: 1,
				speed: 300,
			} as SwiperOptions,
			isBeginning: true,
			isEnd: false,
			clsPreparedComponents: {
				firstSliderItem: false,
			},
			isVertical: this.swiperOptions?.direction === 'vertical',
			activeIndex: 0,
			slidesLength: 0,
		};
	},
	computed: {
		hasArrows(): boolean {
			return !!this.options?.hasArrows;
		},
		swiper(): any {
			return this[this.uid as never];
		},
		sliderOptions(): SwiperOptions {
			if (this.swiperOptions) {
				return this.swiperOptions;
			}

			let sliderOptions: SwiperOptions = { ...this.defaultOptions };

			if (!this.options) {
				return sliderOptions;
			}

			sliderOptions.slidesPerView = this.options.slidesPerView;
			sliderOptions.freeMode = this.options.freeMode;

			sliderOptions = this.setSlidesPerGroupAndView(sliderOptions);

			if (this.options.autoplay && sliderOptions.autoplay) {
				(sliderOptions.autoplay as AutoplayOptions).delay =
					this.options.delay || (this.defaultOptions.autoplay as AutoplayOptions).delay;
			}

			if (this.options.delay) {
				sliderOptions.speed = this.options.delay;
			}

			return sliderOptions;
		},
		itemWidth(): any {
			if (this.isVertical) {
				return '100%';
			}

			let amount = this.sliderOptions.slidesPerView;

			if (this.sliderOptions.breakpoints) {
				if (this.BREAKPOINT.IS_TABLET) {
					amount = this.sliderOptions.breakpoints[Breakpoints.TABLET]?.slidesPerView || amount;
				}

				if (this.BREAKPOINT.IS_DESKTOP) {
					amount = this.sliderOptions.breakpoints[Breakpoints.DESKTOP]?.slidesPerView || amount;
				}
			}

			if (typeof amount !== 'number') {
				amount = 1;
			}

			return `${100 / amount}%`;
		},
	},
	mounted() {
		this.setArrowsVisibility();
		this.setActiveIndex();
		this.setSlidesLength();
		this.swiper.on('slideChange', this.onSlideChange);
		this.swiper.on('update', this.setArrowsVisibility);
		this.$root.$on('slider-slide-change', this.setSlide);
	},
	beforeDestroy() {
		this.$root.$off('slider-slide-change', this.setSlide);
	},
	methods: {
		debouncedSwiperResizeHandler() {
			return debounce(this.handleSwiperResize, 300);
		},
		handleSwiperTransitionStart() {
			this.setArrowsVisibility();
		},
		handleSwiperResize() {
			this.setArrowsVisibility();
		},
		setActiveIndex() {
			this.activeIndex = this.swiper.activeIndex + 1;
		},
		setArrowsVisibility() {
			if (this.isCompact) {
				this.isBeginning = false;
			} else {
				this.isBeginning = this.swiper?.isBeginning;
				this.isEnd = this.swiper?.isEnd;
			}
		},
		setSlidesLength() {
			this.slidesLength = this.items?.length || (this.swiper?.slides || []).length;
		},
		prev() {
			this.swiper.slidePrev();
		},
		next() {
			this.swiper.slideNext();
		},
		handleObserver(isVisible: boolean) {
			// When swiper is broken - this.swiper.progress is NaN
			if (!isNaN(this.swiper?.progress) || !isVisible) return;
			this.swiper.update();
		},
		registerItemRendered() {
			this.clsPreparedComponents.firstSliderItem = true;
		},
		onSlideChange() {
			this.setActiveIndex();
			this.$root.$emit('slider-slide-changed', { uid: this.uid, index: this.swiper.activeIndex });

			if (!this.isCompact) {
				this.setArrowsVisibility();
			}
		},
		setSlide({ uid, index }: { uid: string; index: number }) {
			if (uid === this.uid) {
				const speed = this.sliderOptions?.speed || this.defaultOptions.speed;

				this.swiper.slideTo(index, speed);
			}
		},
		setSlidesPerGroupAndView(sliderOptions: SwiperOptions): SwiperOptions {
			let groupSize = 1;

			if (typeof this.options?.slidesPerView === 'number') {
				groupSize = Math.floor(this.options.slidesPerView);

				sliderOptions.slidesPerGroup = groupSize;
			}

			if (this.options?.slidesPerViewTablet || this.options?.slidesPerViewDesktop) {
				sliderOptions.breakpoints = {};

				if (typeof this.options.slidesPerViewTablet === 'number') {
					groupSize = Math.floor(this.options.slidesPerViewTablet);

					sliderOptions.breakpoints[Breakpoints.TABLET] = {
						slidesPerView: this.options.slidesPerViewTablet,
						slidesPerGroup: groupSize >= 2 ? groupSize - 1 : groupSize,
					};
				}

				if (typeof this.options.slidesPerViewDesktop === 'number') {
					groupSize = Math.floor(this.options.slidesPerViewDesktop);

					sliderOptions.breakpoints[Breakpoints.DESKTOP] = {
						slidesPerView: this.options.slidesPerViewDesktop,
						slidesPerGroup: groupSize >= 2 ? groupSize - 1 : groupSize,
					};
				}
			} else if (this.options?.slidesPerGroup) {
				sliderOptions.breakpoints = {
					[Breakpoints.TABLET]: {
						slidesPerGroup: this.options.slidesPerGroup,
					},
				};
			}

			return sliderOptions;
		},
	},
});
