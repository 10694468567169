





































import Vue from 'vue';

export default Vue.extend({
	props: {
		prev: { type: Function, required: true },
		next: { type: Function, required: true },
		isBeginning: { type: Boolean, default: null },
		isEnd: { type: Boolean, default: null },
		isVertical: { type: Boolean, default: false },
		activeIndex: { type: Number, default: null },
		slidesLength: { type: Number, default: null },
		isCompact: { type: Boolean, default: false },
	},
});
