import Vue from 'vue';
import { Link } from '../../types/link';
import { MediaAspectRatio } from '../../types/media';
import { VueObserverVisibilityOptions } from '../../types/vue-observe-visibility';
import Ico from '../Ico/Ico.vue';
import Media from '../Media/Media.vue';
import { ImageTileProps } from './ImageTile.props';

// Component ---------------------------------------------------------------------------------------

export default Vue.extend({
	name: 'ImageTile',
	components: { Ico, Media },
	props: ImageTileProps,
	computed: {
		tag(): string {
			return this.url ? 'Link' : 'div';
		},
		linkData(): Omit<Link, 'text'> | null {
			return this.url ? { href: this.url } : null;
		},
		imageAspectRatio(): MediaAspectRatio {
			if (this.isSquare) {
				return { width: 1, height: 1 };
			}

			return { width: 9, height: 16 };
		},
		handleObserver(): VueObserverVisibilityOptions {
			return (
				!!this.tracking && {
					callback: this.observerCallback,
					once: true,
				}
			);
		},
	},
	methods: {
		observerCallback(isVisible: boolean, _entry: IntersectionObserverEntry) {
			if (!isVisible) return;

			this.tracking?.view?.({
				id: this.uid,
				name: this.$options.name as string,
			});
		},
		handleClick(event: Event) {
			if (!this.url || !this.tracking) return;

			const { pathname } = event.currentTarget as HTMLAnchorElement;

			this.tracking?.click?.({
				id: this.uid,
				name: this.$options.name as string,
				cd122: this.title,
				cd123: pathname,
			});
		},
	},
});
