var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
		'tw-z-10',
		'tw-pointer-events-none',
		{ 'tw-absolute tw-hidden tw-w-full tw-px-2 tw-justify-between md:tw-flex': !_vm.isCompact },
		{ 'tw-top-1/2 tw-transform tw--translate-y-1/2 ': !_vm.isVertical && !_vm.isCompact },
		{ 'tw-flex-col tw-top-0 tw-h-full tw-items-center': _vm.isVertical && !_vm.isCompact },
		{ 'tw-flex tw-items-center tw-px-6 tw-py-4': _vm.isCompact } ]},[_c('SliderArrow',{class:{
			'tw-invisible': _vm.isBeginning,
			'tw-transform tw-rotate-90 tw-flex-grow-0': _vm.isVertical,
		},attrs:{"icon":_vm.isCompact ? 'chevron-left' : 'arrow-left',"text":_vm.$t(_vm.$i18nKeys.slider.prev),"is-compact":_vm.isCompact,"is-vertical":_vm.isVertical},on:{"click":_vm.prev}}),(_vm.isCompact && _vm.activeIndex !== undefined && _vm.slidesLength !== undefined)?_c('SliderCounter',{attrs:{"active-index":_vm.activeIndex,"slides-length":_vm.slidesLength}}):_vm._e(),_c('SliderArrow',{class:{ 'tw-invisible': _vm.isEnd, 'tw-transform tw-rotate-90': _vm.isVertical },attrs:{"icon":_vm.isCompact ? 'chevron-right' : 'arrow-right',"text":_vm.$t(_vm.$i18nKeys.slider.next),"is-compact":_vm.isCompact,"is-vertical":_vm.isVertical},on:{"click":_vm.next}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }