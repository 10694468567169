





import Vue from 'vue';
import { IRenderSizingMixin, RenderSizingMixin } from '../../mixins/render-sizing-mixin';

export default Vue.extend({
	mixins: [RenderSizingMixin],
	props: {
		width: { type: String, default: 'auto' },
	},
	mounted() {
		(this as unknown as IRenderSizingMixin).watchRenderSizing({
			refKey: 'self',
			callback: () => {
				this.$emit('clsRendered');
			},
		});
	},
});
