import { render, staticRenderFns } from "./Slider__Arrow.vue?vue&type=template&id=c6bad758&"
import script from "./Slider__Arrow.vue?vue&type=script&lang=ts&"
export * from "./Slider__Arrow.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../shop/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Ico: require('/opt/jenkins/workspace/ospbuild/osp-store-repository/node/packages/shop/node_modules/@osp/design-system/components/Ico/Ico.vue').default})
