





import Vue from 'vue';

export default Vue.extend({
	props: {
		activeIndex: { type: Number, required: true },
		slidesLength: { type: Number, required: true },
	},
	computed: {
		counter() {
			return `${this.activeIndex}/${this.slidesLength}`;
		},
	},
});
