



















import Vue from 'vue';

export default Vue.extend({
	props: {
		icon: { type: String, required: true },
		text: { type: String, required: true },
		isCompact: { type: Boolean, default: false },
		isVertical: { type: Boolean, default: false },
	},
});
